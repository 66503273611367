<template>
  <v-app>
    <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
      <div>
        <v-tabs v-model="tab" show-arrows background-color="#383e42" icons-and-text dark grow>
          <v-tabs-slider color="green darken-4"></v-tabs-slider>
          <v-tab v-for="(i, idx) in tabs" :key="idx">
            <v-icon large>{{ i.icon }}</v-icon>
            <div class="caption py-1">{{ i.name }}</div>
          </v-tab>
          <v-tab-item>
            <v-card class="px-4">
              <v-card-text>
                <v-form ref="loginForm" @submit.prevent="submitLogin">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="loginEmail"
                        :error-messages="loginEmailErrors"
                        label="Epost"
                        required
                        @input="debouncedLoginEmailTouch"
                        aria-label="Email address for login"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="loginPassword"
                        :error-messages="loginPasswordErrors"
                        label="Lösenord"
                        :append-icon="showLoginPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showLoginPassword ? 'text' : 'password'"
                        required
                        counter
                        @click:append="showLoginPassword = !showLoginPassword"
                        @input="debouncedLoginPasswordTouch"
                        aria-label="Password for login"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="text-right">
                      <router-link to="/forgot-password">Glömt Lösenord?</router-link>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                      <v-btn x-large block color="#383e42" class="white--text" type="submit" :loading="loginLoading">
                        Logga in
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="px-4">
              <v-card-text>
                <v-form ref="registerForm" @submit.prevent="submitRegister">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="registerEnhetsNamn"
                        :error-messages="registerEnhetErrors"
                        label="Enhetsnamn"
                        required
                        @input="debouncedRegisterEnhetsNamnTouch"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                        v-model="registerAdress"
                        :error-messages="registerAdressErrors"
                        label="Adress"
                        required
                        hint="Enhetens besöksadress"
                        @input="debouncedRegisterAdressTouch"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                        v-model="registerPostnr"
                        :error-messages="registerPostErrors"
                        label="Postnummer"
                        required
                        hint="Postnummer för enhetens besöksadress"
                        @input="debouncedRegisterPostnrTouch"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                        v-model="registerOrt"
                        :error-messages="registerOrtErrors"
                        label="Ort"
                        required
                        hint="Enhetens Ort"
                        @input="debouncedRegisterOrtTouch"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="registerOrgNummer"
                        :error-messages="registerOrgNrErrors"
                        label="Organisations-nummer"
                        required
                        hint="Format: xxxxxx-xxxx"
                        @input="debouncedRegisterOrgNummerTouch"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="registerFirstName"
                        :error-messages="registerFirstNameErrors"
                        label="Förnamn"
                        required
                        hint="Kontaktperson"
                        @input="debouncedRegisterFirstNameTouch"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="registerLastName"
                        :error-messages="registerLastNameErrors"
                        label="Efternamn"
                        required
                        hint="Kontaktperson"
                        @input="debouncedRegisterLastNameTouch"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="registerEmail"
                        :error-messages="registerEmailErrors"
                        label="Email"
                        required
                        @input="debouncedRegisterEmailTouch"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="registerTelefon"
                        :error-messages="registerTelefonErrors"
                        label="Telefon"
                        required
                        @input="debouncedRegisterTelefonTouch"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="registerPassword"
                        :error-messages="registerPasswordErrors"
                        label="Lösenord"
                        :append-icon="showRegisterPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showRegisterPassword ? 'text' : 'password'"
                        required
                        counter
                        @click:append="showRegisterPassword = !showRegisterPassword"
                        @input="debouncedRegisterPasswordTouch"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="repeatPassword"
                        :error-messages="registerRepeatPasswordErrors"
                        label="Upprepa Lösenord"
                        :append-icon="showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showRepeatPassword ? 'text' : 'password'"
                        required
                        counter
                        @click:append="showRepeatPassword = !showRepeatPassword"
                        @input="debouncedRepeatPasswordTouch"
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex ml-auto" cols="12" sm="4" xsm="12">
                      <v-btn
                        x-large
                        block
                        color="#383e42" 
                        class="white--text mr-4"
                        type="submit"
                        :loading="registerLoading"
                      >Registrera dig</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">
          Stäng
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';

export default {
  name: "Login",
  mixins: [validationMixin],
  validations: {
    loginEmail: { required, email },
    loginPassword: { required, minLength: minLength(6) },
    registerEmail: { required, email },
    registerPassword: { required, minLength: minLength(6) },
    repeatPassword: { required, sameAsPassword: sameAs("registerPassword") },
    registerFirstName: { required },
    registerEnhetsNamn: { required },
    registerLastName: { required },
    registerAdress: { required },
    registerPostnr: { required },
    registerOrt: { required },
    registerOrgNummer: { required },
    registerTelefon: { required },
  },
  data: function () {
    return {
      loginLoading: false,
      registerLoading: false,
      dialog: true,
      tab: 0,
      tabs: [
        { name: "Logga in", icon: "mdi-account" },
        { name: "Registrera dig", icon: "mdi-account-outline" },
      ],
      loginEmail: "",
      loginPassword: "",
      registerEnhetsNamn: "",
      registerAdress: "",
      registerPostnr: "",
      registerOrt: "",
      registerOrgNummer: "",
      registerFirstName: "",
      registerLastName: "",
      registerEmail: "",
      registerTelefon: "",
      registerPassword: "",
      repeatPassword: "",
      showLoginPassword: false,
      showRegisterPassword: false,
      showRepeatPassword: false,
      snackbar: {
        show: false,
        text: '',
        color: '',
        timeout: 3000
      }
    };
  },
  computed: {
    ...mapState(['savedCurrentUser', 'isAuthenticated']),
    loginEmailErrors() {
      const errors = [];
      if (!this.$v.loginEmail.$dirty) return errors;
      !this.$v.loginEmail.required && errors.push("Epost är obligatoriskt.");
      !this.$v.loginEmail.email && errors.push("Måste vara en giltig Epost.");
      return errors;
    },
    loginPasswordErrors() {
      const errors = [];
      if (!this.$v.loginPassword.$dirty) return errors;
      !this.$v.loginPassword.required && errors.push("Lösenord är obligatoriskt.");
      !this.$v.loginPassword.minLength && errors.push("Lösenordet behöver innehålla minst 6 tecken.");
      return errors;
    },
    registerEmailErrors() {
      const errors = [];
      if (!this.$v.registerEmail.$dirty) return errors;
      !this.$v.registerEmail.required && errors.push("Epost är obligatoriskt.");
      !this.$v.registerEmail.email && errors.push("Måste vara en giltig Epost.");
      return errors;
    },
    registerPasswordErrors() {
      const errors = [];
      if (!this.$v.registerPassword.$dirty) return errors;
      !this.$v.registerPassword.required && errors.push("Lösenord är obligatoriskt.");
      !this.$v.registerPassword.minLength && errors.push("Lösenordet behöver innehålla minst 6 tecken.");
      return errors;
    },
    registerRepeatPasswordErrors() {
      const errors = [];
      if (!this.$v.repeatPassword.$dirty) return errors;
      !this.$v.repeatPassword.sameAsPassword && errors.push("Lösenordet behöver matcha.");
      return errors;
    },
    registerFirstNameErrors() {
      const errors = [];
      if (!this.$v.registerFirstName.$dirty) return errors;
      !this.$v.registerFirstName.required && errors.push("Förnamn är obligatoriskt.");
      return errors;
    },
    registerLastNameErrors() {
      const errors = [];
      if (!this.$v.registerLastName.$dirty) return errors;
      !this.$v.registerLastName.required && errors.push("Efternamn är obligatoriskt.");
      return errors;
    },
    registerEnhetErrors() {
      const errors = [];
      if (!this.$v.registerEnhetsNamn.$dirty) return errors;
      !this.$v.registerEnhetsNamn.required && errors.push("Enhetens namn är obligatoriskt.");
      return errors;
    },
    registerAdressErrors() {
      const errors = [];
      if (!this.$v.registerAdress.$dirty) return errors;
      !this.$v.registerAdress.required && errors.push("Adress är obligatoriskt.");
      return errors;
    },
    registerPostErrors() {
      const errors = [];
      if (!this.$v.registerPostnr.$dirty) return errors;
      !this.$v.registerPostnr.required && errors.push("Postnummer är obligatoriskt.");
      return errors;
    },
    registerOrtErrors() {
      const errors = [];
      if (!this.$v.registerOrt.$dirty) return errors;
      !this.$v.registerOrt.required && errors.push("Ort är obligatoriskt.");
      return errors;
    },
    registerOrgNrErrors() {
      const errors = [];
      if (!this.$v.registerOrgNummer.$dirty) return errors;
      !this.$v.registerOrgNummer.required && errors.push("Organisations nummer är obligatoriskt.");
      return errors;
    },
    registerTelefonErrors() {
      const errors = [];
      if (!this.$v.registerTelefon.$dirty) return errors;
      !this.$v.registerTelefon.required && errors.push("Telefon är obligatoriskt.");
      return errors;
    },
  },
  methods: {
    ...mapActions(['login', 'register']),
    async submitLogin() {
      this.$v.loginEmail.$touch();
      this.$v.loginPassword.$touch();
      if (!this.$v.loginEmail.$invalid && !this.$v.loginPassword.$invalid) {
        this.loginLoading = true;
        try {
          await this.login({
            email: this.loginEmail.toLowerCase(),
            password: this.loginPassword
          });
          this.showSnackbar('Inloggningen lyckades!', 'success');
          this.$router.push('/kliniker');
        } catch (error) {
          this.handleError(error);
        } finally {
          this.loginLoading = false;
        }
      }
    },
    async submitRegister() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.registerLoading = true;
        try {
          await this.register({
            email: this.registerEmail.toLowerCase(),
            password: this.registerPassword,
            enhetsNamn: this.registerEnhetsNamn,
            adress: this.registerAdress,
            postnr: this.registerPostnr,
            ort: this.registerOrt,
            orgNummer: this.registerOrgNummer,
            firstName: this.registerFirstName,
            lastName: this.registerLastName,
            telefon: this.registerTelefon
          });
          this.showSnackbar('Registreringen lyckades! Vänligen kontrollera din e-post för att bekräfta ditt konto.', 'success');
          this.resetForm();
        } catch (error) {
          this.handleError(error);
        } finally {
          this.registerLoading = false;
        }
      }
    },
    handleError(error) {
      console.error('Operation failed:', error);
      let errorMessage = 'Ett fel uppstod. Försök igen senare.';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      this.showSnackbar(errorMessage, 'error');
    },
    showSnackbar(text, color = 'success') {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
    resetForm() {
      this.$refs.registerForm.reset();
      this.$v.$reset();
    },
    debouncedLoginEmailTouch: _.debounce(function() {
      this.$v.loginEmail.$touch();
    }, 300),
    debouncedLoginPasswordTouch: _.debounce(function() {
      this.$v.loginPassword.$touch();
    }, 300),
    debouncedRegisterEnhetsNamnTouch: _.debounce(function() {
      this.$v.registerEnhetsNamn.$touch();
    }, 300),
    debouncedRegisterAdressTouch: _.debounce(function() {
      this.$v.registerAdress.$touch();
    }, 300),
    debouncedRegisterPostnrTouch: _.debounce(function() {
      this.$v.registerPostnr.$touch();
    }, 300),
    debouncedRegisterOrtTouch: _.debounce(function() {
      this.$v.registerOrt.$touch();
    }, 300),
    debouncedRegisterOrgNummerTouch: _.debounce(function() {
      this.$v.registerOrgNummer.$touch();
    }, 300),
    debouncedRegisterFirstNameTouch: _.debounce(function() {
      this.$v.registerFirstName.$touch();
    }, 300),
    debouncedRegisterLastNameTouch: _.debounce(function() {
      this.$v.registerLastName.$touch();
    }, 300),
    debouncedRegisterEmailTouch: _.debounce(function() {
      this.$v.registerEmail.$touch();
    }, 300),
    debouncedRegisterTelefonTouch: _.debounce(function() {
      this.$v.registerTelefon.$touch();
    }, 300),
    debouncedRegisterPasswordTouch: _.debounce(function() {
      this.$v.registerPassword.$touch();
    }, 300),
    debouncedRepeatPasswordTouch: _.debounce(function() {
      this.$v.repeatPassword.$touch();
    }, 300)
  },
  created() {
    this.$store.commit('initializeStore');
  }
};
</script>

<style scoped>
.error-message {
  color: red;
}
.d-block {
  display: block;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
</style>